body {
    color: rgba(255, 255, 255, 0.959) !important;
    overflow-x: hidden;
}

:root {
    --maincolor: linear-gradient(90deg, rgb(66, 245, 236) 0%, rgb(0, 135, 139) 100%);
    --hovercolor: linear-gradient(90deg, rgb(3, 158, 163) 0%, rgb(0, 199, 206) 100%);
}

input::placeholder {
    color: #afafaf !important;
}
textarea::placeholder {
    color: #afafaf !important;
}

.btn-primary {
    background: var(--maincolor) !important;
    padding: 5px 10px !important;
    border: none !important;
    color: black !important;
    font-weight: 600 !important;
}

.btn-primary:hover {
    background: var(--hovercolor) !important;
}

.Navbar-Home {
    background-color: black;
    color: white !important;
    text-align: center;
    position: fixed !important;
    z-index: 1000;
    width: 100%;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.Navbar-Home:hover {
    opacity: 1;
}

.navbar-brand h2 {
    font-family: serif;
}

.navbar-brand img {
    width: 80px;
}

.navbar-brand img:hover {
    transform: scale(1.01);
}

.nav-hover {
    position: relative;
}

.nav-hover::after {
    content: '';
    width: 0;
    height: 3px;
    background: var(--maincolor);
    position: absolute;
    left: 0;
    bottom: 0;
    transition: 0.2s ease-out;
}

.nav-hover:hover::after {
    width: 100%;
}

.navbar-toggler {
    cursor: pointer;
    border: none !important;
    color: rgba(223, 223, 223, 0.74) !important;
}




.Home-Background {
    background-image: url(./assets/images/home-background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
}

.Home-Page-Content h2 {
    font-size: 4rem;
    font-weight: 600;
    color: beige;
    font-family: monospace;
}

.Home-Page-Content p {
    font-size: 1.1rem;
    font-weight: 400;
    color: rgba(206, 206, 206, 0.774);
}



@media (max-width: 768px) {
    .Home-Page-Content h2 {
        font-size: 2.5rem;
        text-align: center;
    }

    .Home-Page-Content p {
        font-size: 1rem;
        text-align: justify;
    }

    .home-image-profile img {
        display: none;
    }

    .Home-Page-Content-details {
        height: 100vh;
    }

    .about-section-head {
        font-size: 16px !important;
    }
    .About-content img {
        width: 400px;
    }
    .Footer-section{
        font-size: 16px !important;
    }
    .Contact-Box h5{
        font-size: 14px;
    }
    .Skill-content-Container h6 {
        font-size: 16px !important;
        font-weight: 700 !important;
    }

}

.skill-card {
    background: #03030310;
    height: 150px;
    border: 2px solid rgba(66, 245, 236, 0.616);
    border-radius: 10px;
    font-size: 25px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s, background-color 0.3s, border 0.3s;
}

.skill-card:hover {
    transform: scale(1.05);
    cursor: pointer;
    background: #03030334;
    border: 2px solid rgb(150, 208, 255);

}

.Skill-content-Container {
    background: #03030310;
    border: 2px solid rgba(66, 245, 236, 0.616);
    border-radius: 10px;
}

.Skill-content-Container h1 {
    color: #fff;
    text-transform: capitalize;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 1.1px;
}

.Skill-content-Container h6 {
    color: #999999;
    text-transform: capitalize;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 1.1px;
    text-align: center
}

.Skill-content-Container h4 {
    color: #f5f5f5;
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1.1px;

}

.Skill-content-Container h5 {
    color: #999999;
    /* text-transform: capitalize; */
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1.1px;

}

.skill-card.active {
    border: 5px solid rgb(156, 255, 250);
}

.Skill-content-Container img:hover {
    transform: scale(1.05);
    transition: 200ms ease-in-out;
    cursor: pointer;
}

@media (max-width: 767px) {
    .skill-card {
        height: 100px;
        font-size: 20px;
        flex: 1 0 auto;
    }

    .Home-Page-Content #skills .row {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
    }

    .Home-Page-Content #skills .row::-webkit-scrollbar {
        display: none;
    }

    .Home-Page-Content #skills .col-sm-3 {
        flex: 1 0 auto;
        max-width: none;
    }
}

.About-content h1 {
    text-transform: capitalize;
    letter-spacing: 1.1px;
    font-weight: 700;
    font-size: 40px;
}

.custom-hr {
    height: 5px;
    background-color: rgb(0, 226, 215) !important;
    width: 150px;
}

.About-content p {
    font-weight: 500;
    font-size: 20px;
    color: #868686;
    text-align: justify;
}

.about-section-head {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    border-radius: 2px;
}

.about-section-head.active {
    background-color: rgba(212, 255, 255, 0.116);
    border-bottom: 4px solid rgb(0, 226, 215) !important;
}

.Skill-content-Container li {
    font-size: 18px;
    color: #e4e4e4;
    font-weight: 500;
    letter-spacing: 1.1px;
}

.Skill-content-Container ul {
    list-style-type: none;
}

.education-section h4 {
    font-size: 25px;
    color: #ffffff;
}

.education-section h5 {
    font-size: 20px;
    color: #e6e6e679;
}

.education-section h6 {
    font-size: 18px;
    color: #afafaf79;
}

.Project-Card {
    background-color: #00000054;
    border-radius: 5px;
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.10), 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
}

.Project-Card h1 {
    font-size: 40px;
    text-transform: capitalize;
    letter-spacing: 1.1px;
    color: #fff;
}

.Project-Card h2 {
    font-size: 20px;
    letter-spacing: 1.1px;
    color: #9e9e9e;
    font-weight: 600;
}

.Project-Card h3 {
    font-size: 16px;
    letter-spacing: 1.1px;
    color: #888888;
    font-weight: 600;
}

.Project-Card h4 {
    font-size: 25px;
    color: #e0e0e0;
    font-weight: 600;
}

.Project-Card h6 {
    font-size: 16px;
    letter-spacing: 1.1px;
    color: #707070bb;
    font-weight: 600;
    text-align: center;
}

.tech-image img:hover {
    transform: scale(1.1);
    transition: 200ms ease-in-out;
    cursor: pointer;
}

.main-section-heading {
    letter-spacing: 1.1px;
}

.Contact-Box{
    background-color: #00000054;
    border-radius: 5px;
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.10), 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
}

.Contact-Box h5{
    color: #a0a0a0;
    letter-spacing: 1.1px;
}
.Footer-section{
    background-color: #1d1d1d;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: #8f8f8fa8;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1.1px;
}